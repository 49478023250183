
import { computed } from "vue";
import { useStore, useRoute } from "../../helpers/compositionApi";
import { sitePath } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlock",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		disableLink: {
			type: Boolean,
			default: false,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();
		const route = useRoute();
		const dataSite = computed(() => sitePath(store));

		const internalLink = computed(() => {
			return (
				props?.settings?.linkInternal ||
				props.settings?.linkUrl?.startsWith("/")
			);
		});

		const blockTag = computed(() => {
			if (props.settings?.linkUrl && !props.isEditable) {
				return internalLink.value ? "NuxtLink" : "a";
			}
			return "div";
		});

		const linkUrl = computed(() => {
			if (props.disableLink) {
				return "";
			}
			if (props.settings?.linkUrl?.charAt(0) === "#") {
				return {
					hash: props.settings.linkUrl,
					path: route.path,
				};
			}
			return props.settings?.linkUrl;
		});

		const linkTarget = computed(() => {
			return props.settings.linkInternal ? "_self" : "_blank";
		});

		const blockComponent = computed(() => {
			switch (props.settings.type) {
				case "button":
					return "CoreBlockButton";
				case "breadcrumbs":
					return "CoreBlockBreadcrumbs";
				case "contact-card":
					return "CoreBlockContactCard";
				case "contact-hero":
					return "CoreBlockContactHero";
				case "columns":
					return "CoreBlockColumns";
				case "container":
				case "section":
				case "accordion":
				case "toggle":
				case "tabs":
					return "CoreBlockContainer";
				case "content-card":
					return "CoreBlockContentCard";
				case "divider":
					return "CoreBlockDivider";
				case "file":
					return "CoreBlockFile";
				case "grid":
					return "CoreBlockGrid";
				case "heroComponent":
					return "CoreBlockHeroComponent";
				case "hero":
					return "CoreBlockHero";
				case "calculators":
					return "CoreBlockCalculators";
				case "html":
					return "CoreBlockHtml";
				case "icon":
					return "CoreBlockIcon";
				case "image":
					return "CoreBlockImage";
				case "media":
					return "CoreBlockMedia";
				case "rows":
					return "CoreBlockRows";
				case "social-links":
					return "CoreBlockSocialLinks";
				case "text":
					return "CoreBlockText";
				case "video":
					return "CoreBlockVideo";
				case "pencil-banner":
					return "CoreBlockPencilBanner";
				case "rich-text":
					return "CoreBlockRichText";
				case "form":
					return "CoreBlockForm";
				case "calendly":
					return "CoreBlockCalendly";
				case "map":
					return "CoreBlockMap";
				case "select":
					return "CoreBlockSelect";
				case "anchor-link-nav":
					return "CoreAnchorLinkNavigation";
				case "articles":
					return "CoreBlockArticles";
				case "article":
					return "CoreBlockArticleCard";
				default:
					return "CoreBlockUnknown";
			}
		});

		const blockId = computed(() => props.settings.id);

		const blockStyle = computed(() => {
			const styleSetting = props.settings.style || "";
			return [styleSetting]
				.join("\n")
				.replaceAll("#id", `#${blockId.value}`);
		});

		const blockType = computed(() => props.settings.type);

		const blockVariants = computed(() => {
			const variants = [];
			if (props.settings.variants) {
				for (const [variantKey, variantValue] of Object.entries(
					props.settings.variants
				)) {
					// go through every variant saved that is not of the "_custom" key
					if (variantKey !== "_custom") {
						// variants defined without options are just toggles
						// and if they are true, then apply the variant key
						if (variantValue === true) {
							variants.push(variantKey);
						}
						// variants defined with specific string value options
						// should apply the selected value
						else {
							variants.push(variantValue);
						}
					}
				}
			}
			if (!variants.length) {
				variants.push("base");
			}
			return variants;
		});

		const blockClasses = computed(() => {
			const classList = ["block", `block_${blockType.value}`];
			blockVariants.value.forEach((variant) => {
				if (variant) {
					classList.push(`block_${blockType.value}_${variant}`);
				}
			});
			// anything added to the _custom key is a string w/ space delimited custom classes
			if (props.settings.variants?._custom) {
				classList.push(props.settings.variants._custom.split(" "));
			}
			// Add global animation from site settings
			if (dataSite.value?.animationStyle) {
				classList.push(
					"animate__animated animate__" +
						dataSite.value.animationStyle
				);
			}

			return classList;
		});

		return {
			dataSite,
			blockTag,
			linkUrl,
			linkTarget,
			internalLink,
			blockComponent,
			blockId,
			blockStyle,
			blockType,
			blockVariants,
			blockClasses,
		};
	},
};
